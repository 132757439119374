
.orderStatus {
    font-weight: bold;
}

.orderStatus::after {
    content: " ";
    display: inline-block;
    border: 1px solid;
    width: 65%;
    height: 0;
    margin-left: 8px;
    vertical-align: super;
}

.orderStatus.active::after {
    border-color: #00BB71;
}

.orderStatus.inactive::after {
    border-color: #8F959E;
}

.active {
    color: #00BB71;
}
.inactive {
    color: #8F959E;
}

.orderCard {
    margin: 16px;
    border-radius: 0;
    border-width: 0;
    background-color: #f5f6fa;
    overflow: hidden;
    transition: 0.1s;
    padding-top: 0;
}

.orderCard.collapse {
    margin-top: 16px;
    margin-bottom: 8px;
}

.orderCard > div {
    padding: 16px !important;
    padding-bottom: 0 !important;
}
.orderCard > div:nth-child(1) {
    padding-top: 0 !important;
}

.orderDetail {
    margin-bottom: 6px;
}

.label {
    opacity: 0.6;
}

.orderRow {
    margin: 0;
    margin-bottom: 8px;
    color: var(--dark-grey);
    font-size: 14px;
}

.trackingButton {
  width: 100%;
  background: var(--black) !important;
  color: white;
  /* margin-top: 16px; */
  height: 50px;
}
.trackingButton:global.ant-btn.ant-btn-default:hover,
.trackingButton:global.ant-btn.ant-btn-default:focus,
.trackingButton:global.ant-btn.ant-btn-default:active {
  color: white !important;
}

