.detailsModal {
  top: 24px;
  left: 24px;
  margin: 0;
  text-align: center;
}
.detailsModal > div:nth-child(2) {
  border-radius: 0;
  /* border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important; */
  /* height: -webkit-fill-available; */
  /* height: calc(100svh - 36px); */
  height: calc(100dvh - 48px);
  width: calc(100vw - 48px);
  max-width: 500px;
  position: fixed;
  overflow: visible;
  overflow-y: scroll;
  /* padding-bottom: 80px; */
}

.detailsModal :global .slick-slide > div {
  display: flex !important;
  height: calc(100dvh - 192px);
  flex-flow: column;
  /* height: 100%; */
  /* align-content: space-between;
    flex-wrap: wrap; */
}

.imgContainer {
  flex: auto;
  display: flex;
  justify-content: center;
}

.onboardingImage {
  width: 80%;
  max-width: 300px;
  object-fit: contain;
  margin: 0 auto;
}

.onboardingTitle {
  font-size: 22px;
  line-height: 115%;
  margin-bottom: 0;
}
.onboardingDescription {
  color: var(--microcopy-text);
  margin-top: 8px;
  line-height: 142%;
}

.dots li {
  margin-bottom: 125px;
  width: 10px !important;
}
.dots li button {
  height: 8px !important;
  width: 8px !important;
  border-radius: 8px !important;
  background: var(--microcopy-text) !important;
}
.dots :global li.slick-active button {
  background: var(--humamy-yellow) !important;
}

.modalEditBar {
  transition: 0.2s;
}

.button {
  height: 56px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  margin-inline-start: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.button.primary {
  background: #fdc300;
}
.buttonArrow {
  height: 56px;
  width: 60px;
  transition: 0.3s;
  margin-right: 8px;
  overflow: hidden;
}

/* MEDIA QUERIES ---------------------------------------------------------------- */

@media only screen and (min-width: 500px) {
  .detailsModal {
    /* top: calc(50% - 360px); */
    top: 0;
    left: 12px;
    margin: 0 auto;
  }
  .detailsModal > div:nth-child(2) {
    /* max-height: 700px; */
    height: 100dvh;
  }
  .detailsModal :global .slick-slide > div {
    height: calc(100dvh - 143px);
  }
  .dots li {
    margin-bottom: 105px;
  }
}

@media only screen and (max-height: 600px) {
  .onboardingImage {
    width: 70%;
  }
}

@media only screen and (max-height: 510px) {
  .dots li {
    margin-bottom: 115px;
  }
  .dots li button {
    height: 6px !important;
    width: 6px !important;
  }
  .onboardingImage {
    width: 60%;
  }
}
