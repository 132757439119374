.infoLabel {
  font-weight: 500;
  font-size: 13px;
  color: #8f959e;
  margin-bottom: 4px;
}

.inputValue {
  font-weight: 400;
  font-size: 16px;
  color: #1e1e1e;
}

.infoContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
}

.infoTitle {
  font-weight: 800;
  font-size: 18px;
  color: #444845;
  margin-bottom: 0;
}

.infoPack {
  margin-top: -4px;
}

.collapse {
  border: 1px solid #f5f6fa;
  border-radius: 0;
  margin-top: 16px;
  margin-bottom: 16px;
}

:global .ant-collapse-header {
  font-weight: 600;
  font-size: 14px;
}

.button {
  height: 50px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  margin-bottom: 16px;
  box-shadow: none !important;
  width: calc(100%);
  color: #1e1e1e;
}
.button:disabled {
  background-color: #aeb5c0;
  color: white;
}
.button:hover {
  background: #dfe0e6 !important;
}
.buttonIcon {
  float: right;
  font-size: 20px;
  margin-top: 2px;
}

.label {
  opacity: 0.6;
}

.notVisible {
  display: none;
}

.bodySmall {
  color: #444845;
  margin: 24px;
  text-align: center;
}

.invoiceForm > .button {
  color: white;
  background: #1e1e1e;
  margin-bottom: 12px;
}

.invoiceForm > div {
  margin-bottom: 16px;
}

.editIcon {
  padding: 10px;
  margin: -10px;
  margin-top: -8px;
}
.editIcon:active {
  opacity: 0.5;
}

.subEditBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--disabled-grey);
  padding: 8px 0 8px 16px;
}

.smallLabel {
  display: flex;
  align-items: center;
  gap: 6px;
}

.smallModalFooter {
  display: flex;
  flex-wrap: wrap;
}
.smallModalFooter .button {
  flex: 1;
}
