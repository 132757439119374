.faqList {
  border-radius: 0;
  background-color: #f5f6fa;
}

.infoTitle {
  font-weight: 800;
  font-size: 18px;
  color: #444845;
  margin-bottom: 24px;
  margin-top: 24px;
}

:global .ant-collapse-item {
  border-bottom: 1px solid #ebebeb !important;
}
:global .ant-collapse-item:last-child {
  border-bottom: none !important;
}
:global .ant-collapse-content-box {
  padding-bottom: 4px !important;
  padding-top: 0 !important;
}
