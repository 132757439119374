.titleCentered {
  font-size: 18px;
  font-weight: 800;
  color: var(--black);
  text-align: center;
}

.text {
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  color: var(--dark-gray);
  margin-bottom: 24px;
  margin-top: 16px;
}

.inputValue {
  font-weight: 400;
  font-size: 16px;
  color: #1e1e1e;
  display: block;
  margin-bottom: 8px;
}

.infoPack {
  margin-bottom: 16px;
  background: #fafbfe;
  padding: 16px;
  padding-bottom: 1px;
}

.recapLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.recapBold {
  font-weight: 800;
  display: flex;
  align-items: center;
}
.editIcon {
  padding: 10px;
  margin: -10px;
  margin-top: -8px;
}
.editIcon:active {
  opacity: 0.5;
}
.discountNote {
  font-size: 14px;
  color: #1e1e1e;
  opacity: 0.6;
  text-align: right;
  margin-bottom: 10px;
  margin-top: -16px;
}

.body {
  font-size: 15px;
  color: #444845;
}

.bodySmall {
  color: #444845;
  margin-top: 8px;
  margin-bottom: 16px;
  list-style: circle !important;
}

.noteWithIcon {
  font-size: 14px;
  color: var(--dark-gray);
  display: flex;
  margin-top: -8px;
  gap: 8px;
  line-height: 1.5;
  align-items: center;
}
.noteWithIcon img {
  opacity: 0.4;
}

.frequencyDropdown {
  display: flex;
  flex-flow: column;
  gap: 0;
}
.frequencyNote {
  color: var(--green);
  background-color: var(--light-green);
  padding: 14px 16px 14px 16px;
  display: flex;
  gap: 14px;
  margin-bottom: 8px;
}

:global .ant-list-item {
  font-size: 15px;
}

.collapse {
  border: 1px solid #f5f6fa;
  border-radius: 0;
  margin-top: 24px;
  margin-bottom: 16px;
}

.button {
  height: 60px;
  width: 100%;
  background: var(--light-grey);
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
}

.button.primary {
  background: var(--humamy-yellow);
}
.button.primary:hover {
  background: var(--humamy-yellow-alt);
}

.button.secondary {
  color: white;
  background: black;
}

.button:active {
  opacity: 0.4;
}

.button:disabled {
  background-color: #aeb5c0;
  color: white;
}

.buttonIcon {
  float: right;
  font-size: 20px;
  margin-top: 2px;
}

.formInput {
  margin-bottom: 16px;
}

.formInput > div > div > div > div {
  height: 50px;
}

.datePickerInput input::placeholder {
  font-weight: 600;
  color: #1e1e1e !important;
}

.editIcon {
  padding: 10px;
  margin: -10px;
  margin-top: -8px;
}
.editIcon:active {
  opacity: 0.5;
}
.editBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--disabled-grey);
}

:global .ant-picker {
  background-color: #f5f6fa !important;
  border-radius: 0 !important;
  height: 50px !important;
  box-shadow: none !important;
  margin-bottom: -100px !important;
  border: none !important;
  width: 100%;
}

:global .ant-picker-focused {
  outline: 2px solid #cfd4dc !important;
}

:global .ant-picker .ant-picker-input > input:hover {
  border-inline-end-width: 0 !important;
  outline: none !important;
}

:global .ant-select-selector {
  background-color: #f5f6fa !important;
  border-radius: 0 !important;
  height: 50px !important;
  box-shadow: none !important;
  margin-bottom: -100px !important;
  border: none !important;
  align-items: center;
}

:global .ant-select-arrow {
  top: 20px !important;
  margin-top: 0 !important;
}

:global .ant-picker-input > input:focus {
  outline: none !important;
}

.dropdown {
  border-radius: 0;
}

.dropdown > div {
  border-radius: 0 !important;
}

:global .ant-select-item-option-selected {
  background-color: rgb(247, 242, 242) !important;
}

:global .ant-list-item {
  border-block-end: none !important;
  padding: 4px 0 !important;
  color: #444845 !important;
}

mark {
  background-color: transparent !important;
}
