.body {
  padding: 0 16px;
  text-align: center;
}

.timeline {
  width: 3px;
  height: calc(100% - 120px);
  background: linear-gradient(180deg, #e9eeec 0%, rgba(233, 238, 236, 0) 100%);
  margin-top: 24px;
  margin-left: 8px;
}
.timeline img {
  margin-left: -10px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--light-green);
  margin-left: -30px;
  position: absolute;
  margin-top: 9px;
}

.listPosition {
  font-size: 48px;
  color: var(--green);
  font-weight: 500;
  text-align: center;
}

.listItem {
  display: flex;
  flex-flow: row;
  padding-right: 5px;
  gap: 8px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: -2px;
  align-items: flex-start;
  line-height: 128.5%;
}

@media only screen and (min-width: 992px) {
  .body {
    padding-bottom: 40px;
  }
}
