.filtersBar {
  display: flex;
  padding-left: 16px;
  padding-right: 5px;
  align-items: center;
  max-width: 100%;
  height: 54px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: 0.3s;
  background: white;
  top: 0;
  z-index: 20;
  scroll-behavior: smooth;
}

.filtersBar::-webkit-scrollbar {
  display: none;
}

.filtersBarWrapper {
  padding: 10px 0;
  position: sticky;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 20;
  margin-bottom: 8px;
}

.searchContainer {
  overflow: hidden;
  flex: 1;
  height: 54px;
  padding-top: 2px;
}

.searchBtn {
  padding: 8px;
  padding-right: 16px;
  margin-top: 4px;
  cursor: pointer;
}

.filterPill {
  border-radius: 20px;
  border: 2px solid var(--light-grey);
  display: flex;
  padding: 0 15px;
  height: 80%;
  white-space: nowrap;
  margin-right: 8px;
  flex: 0;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
}
.filterPill.selected {
  border: 2px solid #c4ccd9;
}

.filterPill span {
  transition: 0.2s;
  margin-top: 2px;
}
