.bigTitle {
  color: var(--green);
  font-size: 28px;
  font-weight: 800;
  letter-spacing: -0.84px;
  line-height: 113%;
  width: 95%;
  text-align: center;
  margin-bottom: 32px;
}

.modalEditBar {
  position: fixed;
  top: calc(100dvh - 92px);
  width: 100vw;
  background-color: white;
  box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.1);
  height: 92px;
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 16px;
}

.a {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  height: 60px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  max-width: 380px;
}
.button.primary {
  background: #fdc300;
}
.button:disabled {
  background-color: #aeb5c0;
  color: white;
}
