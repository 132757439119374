.title {
  font-size: 24px;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  width: 200px;
  line-height: 115%;
}

.secondaryTitle {
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  width: 200px;
  line-height: 115%;
}

.backBtnRow {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}

.backButton {
  border: none;
  box-shadow: none;
  transition: 0.3s;
}
.backButton:active {
  opacity: 0.4;
}

@media only screen and (min-width: 992px) {
  .title {
    width: 100%;
  }
}
