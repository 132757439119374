.detailsModal {
  top: 0;
  left: 0;
  margin: 0;
}
.detailsModal > div:nth-child(2) {
  border-radius: 0;
  height: -webkit-fill-available;
  /* height: calc(100svh - 36px); */
  height: 100dvh;
  width: 100vw;
  max-width: 500px;
  position: fixed;
  overflow: visible;
  overflow-y: scroll;
  padding-bottom: 80px;
}

.closeIcon {
  margin-top: -12px;
  margin-right: 16px;
  /* box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.25); */
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.6));
  position: fixed;
}

.infoContainer {
  margin-top: 32px;
}

.modalEditBar {
  position: fixed;
  top: calc(100dvh - 92px);
  margin-left: -24px;
  width: 100vw;
  /* width: 100%; */
  max-width: 500px;
  background-color: white;
  box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.1);
  height: 92px;
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 16px;
}

.button {
  height: 60px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
}
.button.primary {
  background: #fdc300;
}
.button:disabled {
  background-color: #aeb5c0;
  color: white;
}

/* MEDIA QUERIES ---------------------------------------------------------------- */

@media only screen and (min-width: 500px) {
  .detailsModal {
    top: calc(50% - 360px);
    margin: 0 auto;
  }

  .detailsModal > div:nth-child(2) {
    /* height: fit-content; */
    /* max-height: calc(100vh - 32px); */
    height: 700px;
  }

  .modalEditBar {
    width: calc(100vw - 36px);
    /* top: unset; */
    top: calc(50% + 250px);
    /* bottom: calc(50% - 250px); */
  }
}
