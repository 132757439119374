.button {
  height: 60px;
  width: 100%;
  background: #f5f6fa;
  font-size: 18px;
  width: calc(100%);
}
.button:hover {
  background: #e8e9ee;
}

.button.primary {
  background: #ffffff;
}

.button:disabled {
  background-color: #aeb5c0;
  color: white;
}

.button.link span {
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
}
.button.link:hover {
  opacity: 0.7;
}

.buttonQuiz {
  height: 60px;
  width: calc(100% - 48px);
  background: white;
  font-weight: 800;
  font-size: 18px;
  border-radius: 0;
  border: none;
  color: #1e1e1e;
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.headerTitle {
  flex: 1;
  text-align: center;
  margin-left: -40px;
  margin-top: 8px;
}
.headerTitle h1 {
  font-size: 18px;
  font-weight: 800;
}

.title {
  color: var(--green);
  text-align: left;
  font-size: 28px;
  line-height: 32px;
  margin-top: -10px;
}
.titleSmaller {
  color: var(--green);
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  margin-top: -8px;
  margin-bottom: 24px;
}

.titleCentered {
  color: var(--green);
  font-size: 28px;
  line-height: 32px;
  margin: 24px;
  margin-bottom: 40px;
  margin-top: 8px;
  text-align: center;
}

.titleRow {
  color: var(--green);
  text-align: left;
  line-height: 32px;
  font-size: 28px;
  margin-bottom: 16px;
  margin-top: -10px;
}

.titleSmall {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 16px;
  /* color: #1e1e1e; */
}

.titleMembership {
  color: var(--green);
  text-align: left;
  font-size: 28px;
  line-height: 32px;
  padding: 0;
  margin: 0 0 32px 0;
}

.textLeft {
  text-align: left;
  font-size: 15px;
  line-height: 21px;
  color: var(--green);
  /* padding: 0 24px 24px 24px; */
  margin-bottom: 24px;
  margin-top: 8px;
}
.textLeftSmall {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: var(--green);
  margin-bottom: 16px;
}

.detailsBtn {
  font-weight: 500;
  font-size: 15px;
  text-decoration-line: underline;
  color: #00603a;
  display: flex;
  gap: 1px;
  align-items: center;
  margin-top: 3px;
}

.banner {
  width: 100%;
  margin-bottom: 16px;
  background-color: var(--light-grey);
  overflow: hidden;
}

.bannerBox {
  padding: 24px;
  margin-bottom: 0;
}

.bannerBox .textLeft {
  color: var(--dark-grey);
  margin-bottom: 12px;
  font-size: 16px;
}
.bannerBox .textLeft b {
  color: var(--black);
  font-size: 18px;
  line-height: 160%;
}

.orderIcon {
  float: right;
  margin-right: -24px;
  margin-top: -24px;
}

.buttonLeftAligned {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.bannerMembership {
  height: auto;
  width: 100%;
  background: #fdc300 no-repeat url("/public/icons/heart.svg");
  padding: 24px;
  background-size: 70px;
  background-position: 104% 40%;
  margin-bottom: 16px;
}

.bannerQuiz {
  position: relative;
  height: calc(100dvh - 146px);
  width: 100%;
  background-color: #fdc300;
  overflow: hidden;
  background-image: url("/public/images/table.jpg");
  background-size: cover;
}

.image {
  width: 100%;
}

.boxImage {
  width: 100%;
  height: 26vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -5vw;
}

.carousel {
  display: flex;
  width: calc(100% + 48px);
  gap: 16px;
  /* margin-bottom: 32px; */
  overflow-x: scroll;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  margin-bottom: 24px;
}
.carousel::-webkit-scrollbar {
  display: none;
}

.carouselImage {
  /* Bigger images */

  /* width: 280px;
  height: 230px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -50px;
  flex: none; */

  /* Smaller images */

  width: 195px;
  height: 139px;
  background-repeat: no-repeat;
  background-position: -10px -53px;
  background-size: 110%;
}

.carouselTitle {
  color: var(--green);
  font-weight: 800;

  /* Bigger images */

  /* font-size: 15px;
  margin-top: 16px; */

  /* Smaller images */
  font-size: 14px;
  margin-top: 8px;
  line-height: 19px;
}

.imageOrder {
  width: calc(100% + 48px);
}

.imageHeart {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  top: 0;
}

.Quiz {
  padding: 24px;
  position: absolute;
  z-index: 1;
}

.logo {
  width: 55px;
  min-height: auto;
}

.logoDesktop {
  margin: 16px;
  display: none;
}

.logoTop {
  width: 80px;
  text-align: center;
  position: fixed;
  top: 2vh;
  left: 50%;
  margin-left: -40px;
}

.center {
  text-align: center;
  width: 100%;
  margin-top: 50%;
}

.bannerMembershipExtended {
  height: auto;
  width: 100%;
  background: #fdc300;
  padding: 24px;
  margin-bottom: 16px;
}

.imageMembership {
  width: calc(100% + 48px);
  margin: -24px;
  margin-bottom: -16px;
}

.listItem {
  display: flex;
  flex-flow: row;
  gap: 8px;
  font-size: 16px;
  font-weight: 800;
  color: var(--green);
  margin-bottom: 12px;
  align-items: center;
}

.explanationStep {
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
  color: var(--green);
  margin-bottom: 32px;
}

.stepImage {
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 53%;
  height: 0;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
}

.textSmallCentered {
  font-size: 15px;
  margin-top: 0;
}

.stepNumber {
  margin-top: -54%;
  width: 10%;
  min-width: 51px;
}

.membershipOptionalBanner {
  background: var(--lagoon);
  color: white;
  margin: -24px;
  margin-top: 24px;
  padding: 16px;
  padding-top: 12px;
  flex-flow: row;
  align-items: center;
  gap: 8px;
}
.membershipOptionalTooltip {
  background: var(--lagoon);
  text-align: left;
  color: white;
  padding: 12px;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 24px;
  padding: 16px;
  /* margin-top: -120px; */
}
.tooltipTip {
  position: absolute;
  right: 45px;
}

.premiumIcon {
  position: relative;
  bottom: 0.7ex;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  background: url("/public/icons/premium.svg") no-repeat;
  background-size: cover;
}

/* MODAL --------------------------------------------------------------------------- */

.fullscreenModal {
  margin: 0;
}

.fullscreenModal > div:nth-child(2) {
  border-radius: 0;
  height: -webkit-fill-available;
  height: 100dvh;
  width: 100vw;
  max-width: 500px;
  position: fixed;
  overflow: visible;
  overflow-y: scroll;
}

.fullscreenModal.paddingBottom :global .ant-modal-footer {
  height: 180px;
}

.padding0 > div:nth-child(2) {
  padding-bottom: 24px;
}

.detailsTitle {
  font-weight: 800;
  font-size: 24px;
  line-height: 23px;
  color: var(--green);
  margin-bottom: 24px;
  margin-top: 8px;
  max-width: calc(100% - 110px);
  padding-right: 16px;
}

.ctaBar {
  position: fixed;
  top: calc(100dvh - 70px);
  left: 0;
  width: 100vw;
  max-width: 500px;
  height: 92px;
  padding: 16px;
  background: white;
}

/* MEDIA QUERIES -------------------------------------------------------------------- */

@media only screen and (min-width: 537px) {
  .ctaBar {
    left: 50%;
    margin-left: -260px;
  }

  .fullscreenModal {
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .boxImage {
    height: 13vw;
    background-position: 0 -2.5vw;
  }
}

@media screen and (min-width: 992px) {
  .logoDesktop {
    display: block;
  }
  .logoMobile {
    display: none;
  }
  .headerTitle {
    margin-left: 0;
  }
  /* 
  .textLeft,
  .title {
    width: 430px;
  } */

  .bannerQuiz {
    height: calc(100vh - 60px);
  }

  .imageQuiz {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1200px) {
  .boxImage {
    height: 9vw;
    background-position: 0 -1.6vw;
  }
}
