.cartRecapBottomBar {
  position: fixed;
  z-index: 30;
  bottom: 0;
  background-color: white;
  width: calc(100vw - 0px);
  height: fit-content;
  transition: 0.3s;
  box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.1);
  font-weight: 800;
  color: #1e1e1e;
  max-height: 100dvh;
}

.cartRecapBottomBarRecapRow {
  transition: 0.2s;
  justify-content: center;
  overflow: hidden;
  max-height: 0;
  padding: 0 16px 0;
}

.cartRecapBottomBarRecapRow.active {
  max-height: 1000px;
  padding: 16px 16px 0;
}

.cartRecapBottomBarRow {
  padding: 16px;
  padding-bottom: 0;
  transition: 0.3s;
  justify-content: center;
  overflow: hidden;
}

.cartRecapBottomBarRow.recapHeader {
  justify-content: space-between;
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 15px;
}
.collapsable {
  overflow: hidden;
  /* transition: .2s ease-out; */
  max-height: 60dvh;
  overflow-y: scroll;
}

.smallDivider {
  top: 1px;
}

.iconContainer {
  align-self: flex-end;
  background-color: #f3f4f8;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  transition: 0.2s;
}
.iconContainer:hover {
  background-color: #e7e8ee;
}
.iconContainer img {
  transition: 0.2s;
  transform-origin: center;
  position: absolute;
}

.cartBadge {
  background-color: #00603a;
  color: white;
  font-weight: 800;
  font-size: 10px;
  line-height: 10px;
  padding: 3px;
  border-radius: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  transition: 0.2s;
}

.message {
  color: var(--green);
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
}

.error {
  color: #ff4c24;
}

.message.error {
  color: #ff4c24;
}

.boxProgress {
  width: 100%;
}

.boxProgressStep {
  height: 5px;
  min-width: 1px;
  background-color: var(--light-green);
  float: left;
}

/* .boxProgressStepStart {
    border-radius: 15px 0 0 15px;
}

.boxProgressStepEnd {
    border-radius: 0 15px 15px 0;
} */

.boxProgressStepSeparator::after {
  border: white 2px solid;
  height: 6px;
  margin-top: -1px;
  content: " ";
  float: left;
}

.boxProgressStep.active {
  background-color: #00603a;
}

.boxProgressStep.error {
  background-color: #ffdbd2;
}

.boxProgressStep.active.error {
  background-color: #ff4c24;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.image {
  width: 60px;
  height: 49px;
  float: left;
  margin-left: 8px;
  margin-right: 8px;
  background-size: cover;
  background-position-y: bottom;
  background-color: #e7e8ee;
}
.imageBadge {
  width: 29px;
  height: 28px;
  float: left;
  margin-left: 8px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.itemTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-right: 8px;
  max-width: calc(100% - 184px);
  padding-right: 0 !important;
}

.qtyLabel {
  font-weight: 700;
  font-size: 15px;
  color: var(--dark-grey);
  margin-right: 8px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  text-align: right;
}

.membershipSwitch {
  min-width: 40px;
}
.membershipSwitch[aria-checked="true"] {
  background: var(--membership-background-color) !important;
}

.overlayExpanded {
  /* background-color: rgba(0, 0, 0, 0.05); */
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
}

.overlayCollapsed {
  display: none;
}

.divider {
  margin: 12px 8px !important;
  min-width: calc(100% - 16px);
}

.cartEmptyState {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #444845;
  margin-bottom: 24px;
}

.priceLabel {
  font-size: 14px;
  font-weight: 500;
  color: var(--disabled-gray);
}

.priceLabelActive {
  color: black;
}

.premiumBadge {
  height: 15px;
  margin-left: 2px;
}

.boxSizeBtn {
  text-decoration: underline;
  font-weight: 500;
  display: flex;
  gap: 8px;
  padding-bottom: 32px;
  justify-content: center;
}
.boxSizeBtn:hover,
.boxSizeBtn:active {
  opacity: 0.6;
  cursor: pointer;
}

/* CART SIDER (DESKTOP) ---------------------------------------------------------------- */

.cartRecapSiderRow {
  position: absolute;
  right: 8px;
  top: 6px;
  /* transition: .3s; */
  z-index: 100;
  justify-content: flex-end;
  width: max-content;
}

.cartRecapSiderRow .boxSizeBtn {
  padding-bottom: 0;
}
.cartRecapSiderRow .cartRecapBottomBarRow {
  padding-left: 8px;
  gap: 16px;
}

.cartSiderWrapper {
  border-left: 1px solid #f0f0f0;
  transition: 0.3s !important;
  z-index: 40;
  /* position: sticky !important; */
  /* height: 100vh; */
}
.cartSiderWrapper.collapsed {
  padding-left: 0;
  padding-right: 0;
  border-left: none;
}

.cartSiderProgressRow {
  width: 100%;
  padding-bottom: 32px;
  transition: 0.3s;
  justify-content: stretch;
}

.cartSiderWrapper .itemTitle {
  font-size: 13px;
  line-height: 1.2;
}

.cartContentWrapper {
  position: sticky;
  top: 0;
  width: 370px;
  height: 100vh;
  /* height: 200px; */
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin; /* Firefox */
  padding: 24px;
}

/* MEDIA QUERIES ---------------------------------------------------------------- */

@media only screen and (min-width: 992px) {
  .cartRecapBottomBar {
    box-shadow: none;
    border-top: 1px solid #f0f0f0;
  }

  .cartRecapBottomBar.sidebarOpen {
    width: calc(100% - 372px);
  }
}
