:global .ant-modal-content {
  border-radius: 0 !important;
}

.alert > div:nth-child(2) {
  border-radius: 0;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

:global .ant-modal .ant-modal-close {
  width: 40px;
  height: 40px;
}

.offerTitle {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -0.4px;
  color: #383838;
  text-align: center;
  margin-bottom: 0;
}

.alert .descriptionBody {
  text-align: center;
  margin-bottom: 24px;
  color: #6d7176;
  margin-top: 0;
}
.alert .descriptionBody b {
  color: #383838;
}

.button {
  height: 60px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
}

.button.yellow {
  background: #fdc300;
  color: black;
}
.button.yellow:hover {
  background: #edb600;
}

.modalImage {
  margin: -24px;
  margin-bottom: -16px;
  width: calc(100% + 48px) !important;
}

.message > div {
  border-radius: 0 !important;
  padding: 16px !important;
}
