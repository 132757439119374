.orderRow {
  margin: 0;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.smallBanner {
  background: var(--green);
  color: white;
  text-align: center;
  padding: 8px 16px 12px 16px;
  font-weight: 600;
  font-size: 15px;
  margin: -24px;
  margin-top: 0;
  margin-bottom: 0;
}

.fullWidthBanner {
  background: #d3e2e7;
  color: var(--green);
  text-align: center;
  margin: -24px;
  margin-top: 0;
  padding: 24px;
}

.unlimitedVideo {
  width: calc(100% + 48px);
  /* width: 100%; */
  margin: -24px;
}

.unlimitedBannerBigScreen {
  background-image: url("/public/images/unlimited-paywall.jpg");
  background-position: top;
  background-size: cover;
  padding-top: 250px;
  padding-bottom: 16px;
  margin-bottom: 32px;
}

.title {
  font-size: 28px;
  margin-top: 15px;
  line-height: 1.3;
}
.unlimitedBannerBigScreen .title {
  font-size: 4vw;
  margin-top: -24px;
}

.unlimitedBannerBigScreen .fullWidthBanner {
  background: none;
}

.titleInfo {
  font-size: 22px;
  margin-top: 32px;
  margin-bottom: 0;
  line-height: 1.3;
  color: var(--green);
}

.highlightYellow {
  display: inline-block;
  padding: 0 0.1em;
  background: var(--humamy-yellow);
}

.infoDetail {
  display: flex;
  flex-flow: column;
  font-weight: 800;
  font-size: 14px;
  line-height: 110%;
  align-items: center;
  gap: 8px;
  max-width: 120px;
}

.smallIcon {
  padding: 10px;
  margin: -10px;
  margin-top: -8px;
}
.smallBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--green);
  padding: 8px;
  padding-bottom: 0;
  opacity: 0.7;
}

.carousel {
  display: flex;
  width: calc(100% + 48px);
  gap: 16px;
  overflow-x: scroll;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-top: 24px;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carouselImage {
  /* Bigger images */

  width: 250px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -45px;
  flex: none;

  /* Smaller images */

  /* width: 195px;
  height: 139px;
  background-repeat: no-repeat;
  background-position: -10px -53px;
  background-size: 110%; */
}
.carouselTitle {
  color: var(--green);
  font-weight: 800;
  line-height: 1.2;

  /* Bigger images */

  font-size: 15px;
  margin-top: 8px;

  /* Smaller images */
  /* font-size: 14px;
  margin-top: 8px;
  line-height: 19px; */
}

.modalImage {
  margin: -24px;
  width: calc(100% + 48px) !important;
  margin-bottom: 8px;
}

.modalImageBigScreen {
  margin: -24px;
  width: calc(100% + 48px) !important;
}

.listItem {
  display: flex;
  flex-flow: row;
  padding-right: 5px;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  color: var(--green);
  margin-bottom: 16px;
  align-items: flex-start;
  line-height: 128.5%;
}

.infoCard {
  width: 100%;
  height: 100%;
  box-shadow: none !important;
  border-radius: 0;
}
.infoCard > div {
  padding: 0 !important;
  padding-top: 16px !important;
  line-height: 1.3;
  margin-bottom: 8px;
}

.column {
  padding: 24px;
  padding-top: 0;
  width: 100%;
}

.cardTitle {
  color: var(--green);
  white-space: normal;
}

.menu {
  padding: 32px 24px 40px 24px;
  color: var(--green);
}

.rowMargin {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 24px;
  /* max-width: 100vw; */
}

/* RECAP --------------------------------------------------- */

.bannerFlex {
  display: flex;
}

.bannerPhoto {
  width: 380px;
  background-image: url("/public/images/freezer.jpg");
  background-size: cover;
  background-position: center;
}

.button {
  height: 60px;
  width: 100%;
  background: white;
  width: calc(100%);
  font-weight: 800;
  border-radius: 0;
  border: none;
  box-shadow: none;
  font-size: 16px;
}
.button:hover {
  background: var(--light-grey);
}
.button.green {
  background: var(--green);
  color: white;
  width: calc(32%);
}
.button.green:hover,
.button.green:active {
  color: white !important;
  background: #003d25;
}
.button.yellow {
  background: var(--humamy-yellow);
  width: calc(25%);
}

.noteWithIcon {
  font-size: 14px;
  color: var(--dark-gray);
  display: flex;
  margin-top: -8px;
  gap: 8px;
  line-height: 1.5;
  align-items: center;
}
.noteWithIcon img {
  opacity: 0.4;
}

.orderRow {
  width: 100%;
  border-radius: 0;
  border-width: 0;
  background: #eeeeee;
}
.orderRow > div {
  flex: 1;
}
.orderRow > div > div {
  padding-left: 16px !important;
}
.orderRow > div > div > span {
  justify-content: space-between;
}

.orderRow :global div.ant-collapse-content {
  padding-left: 0 !important;
  border-radius: 0 !important;
}

.orderRow :global .ant-collapse-content-box {
  padding: 0 !important;
}

.microCopy {
  font-size: 14px;
  width: calc(32%);
  margin: 0 auto;
  margin-bottom: 16px;
  text-align: center;
}

.listPosition {
  font-size: 48px;
  color: var(--green);
  font-weight: 500;
  text-align: center;
}

/* MEDIA QUERIES ----------------------------------------------------------------------- */

@media (min-width: 992px) {
  .menu {
    padding: 32px 100px 40px 100px;
  }
  .carousel {
    width: calc(100% + 24px);
    margin-top: 32px;
  }
}

@media (min-width: 1500px) {
  .unlimitedBannerBigScreen .title {
    font-size: 3vw;
  }
}

@media (min-width: 1600px) {
  .rowMargin {
    padding-left: 0;
    padding-right: 0;
  }
}
