@font-face {
  font-weight: 800;
  src: url("/public/fonts/SofiaPro-Bold.otf") format("opentype");
}

@font-face {
  font-weight: 600;
  src: url("/public/fonts/SofiaPro-Medium.otf") format("opentype");
}

@font-face {
  font-weight: 500;
  src: url("/public/fonts/SofiaPro-Regular.otf") format("opentype");
}

@import url("https://use.typekit.net/yzg3cmt.css");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  border-radius: 0 !important;
  background-color: #f5f6fa !important;
  height: 50px !important;
  border: none !important;
  font-size: 16px !important;
  caret-color: #00603a;
  font-family: "sofia-pro", sans-serif;
}

input[type="text"] {
  padding: 8px 16px !important;
}

input:focus {
  outline: 2px solid #cfd4dc !important;
}

input:disabled {
  color: #444845 !important;
}

label {
  color: #444845 !important;
}

textarea {
  background-color: #f5f6fa !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 16px !important;
  caret-color: #00603a;
}

textarea:focus {
  outline: 2px solid #cfd4dc !important;
}

button {
  box-shadow: none !important;
}
