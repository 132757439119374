.button {
  height: 50px;
  background: var(--light-grey);
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  flex-grow: 1;
  box-shadow: none;
}

.button:hover {
  background: #dfe0e6;
}

.button.primary {
  background: var(--humamy-yellow);
  color: var(--black);
}

.button.primary:hover {
  background: var(--humamy-yellow-alt);
}

.button.primaryBlack {
  background: #1e1e1e;
  color: white;
}

.button.primaryGreen {
  background: var(--green);
  color: white;
}

.button.primaryMembership {
  background: var(--lagoon);
  color: white;
}

.button.primaryWhite {
  background: white;
  color: black;
}

.button.primaryWhite:hover {
  opacity: 0.8;
  color: black;
}

.button.primaryBlack:hover,
.button.primaryGreen:hover,
.button.primaryMembership:hover,
.button.primaryBlack:active,
.button.primaryGreen:active,
.button.primaryMembership:active {
  opacity: 0.8;
  color: white !important;
}

.button:disabled {
  background-color: #aeb5c0;
  color: white;
}

.button.primary:disabled:hover {
  background-color: #aeb5c0;
}

.buttonIcon {
  float: right;
  font-size: 20px;
  margin-top: 4px;
}

.buttonLeftAligned {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.backButton {
  border: none;
  box-shadow: none;
  transition: 0.3s;
}

.backButton:active {
  opacity: 0.4;
}

.confirmationModal > div:nth-child(2) {
  border-radius: 0;
  width: calc(100vw - 36px);
  max-width: 500px;
  margin: 0 auto;
  padding: 24px;
}

.topMicroCopy {
  color: var(--microcopy-text);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.bottomMicroCopy {
  color: var(--dark-gray);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.titleCentered {
  font-size: 18px;
  font-weight: 800;
  color: var(--black);
  text-align: center;
  margin-bottom: 24px;
}

.headerTitle {
  flex: 1;
  text-align: center;
  margin-left: -40px;
  margin-top: 8px;
}

.headerTitle h1 {
  font-size: 18px;
  font-weight: 800;
}

.shopState {
  border-top: 3px solid var(--humamy-yellow);
  color: black;
  font-weight: bold;
  margin: 0 10px 0 0;
  padding-top: 8px;
}

.shopStateDisabled {
  border-top-color: rgba(143, 149, 158, 0.4);
  color: var(--disabled-gray);
  font-weight: normal;
}

/* MEMBERSHIP MODAL --------------------------------------------------------------------------- */

.fullscreenModal {
  margin: 0;
}

.fullscreenModal > div:nth-child(2) {
  border-radius: 0;
  height: -webkit-fill-available;
  height: 100dvh;
  width: 100vw;
  max-width: 500px;
  position: fixed;
  overflow: visible;
  overflow-y: scroll;
  background: var(--light-grey);
}

.fullscreenModal.white > div:nth-child(2) {
  background: white;
}

.fullscreenModal.yellow > div:nth-child(2) {
  background: var(--humamy-yellow);
}

.fullscreenModal :global .ant-modal-footer {
  height: 80px;
}

.fullscreenModal.membershipDetails :global .ant-modal-footer {
  height: 110px;
}

.fullscreenModal.membershipModalSimplified :global .ant-modal-footer {
  height: 45px;
}

.fullscreenModal.newMembershipDetails :global .ant-modal-footer {
  height: 97px;
}

.closeIcon {
  margin-top: -20px;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.6));
  position: fixed;
}

.modalImage {
  margin: -24px;
  width: calc(100% + 48px) !important;
}

.newMembershipDetails .modalImage {
  margin-bottom: -56px;
}

.shippingModalImage {
  margin: -86px -24px -74px;
  width: calc(100% + 48px) !important;
}

.bigTitle {
  color: var(--green);
  font-size: 28px;
  font-weight: 800;
  letter-spacing: -0.84px;
  line-height: 113%;
  width: 95%;
}

.membershipModalSimplified .bigTitle {
  font-size: 26px;
  margin-bottom: 24px;
  margin-top: -32px;
}

.newMembershipDetails .bigTitle {
  text-align: center;
  width: 100%;
  margin-bottom: 28px;
}

.highlight {
  display: inline-block;
  padding: 0 0.1em;
  background: #fffbe9;
}

.highlightYellow {
  display: inline-block;
  padding: 0 0.1em;
  background: var(--humamy-yellow);
}

.highlightGreen {
  display: inline-block;
  padding: 0 0.1em;
  background: var(--green);
}

.infoDetail {
  display: flex;
  font-weight: 800;
  font-size: 14px;
  line-height: 110%;
  align-items: center;
  gap: 8px;
}

.newMembershipDetails .infoDetail {
  flex-flow: column;
  text-align: center;
}

.modalBody {
  font-size: 16px;
  line-height: 1.4;
}

.listItem {
  display: flex;
  flex-flow: row;
  padding-right: 5px;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
  color: var(--green);
  margin-bottom: 12px;
  align-items: flex-start;
  line-height: 128.5%;
}

.membershipModalSimplified .listItem {
  color: var(--black);
}

.listItemBox {
  align-items: center;
  margin-top: 16px;
}

.newMembershipDetails .listItemBox {
  font-size: 15px;
}

.listItem > .membershipBadge {
  margin-left: 3px;
  margin-right: 9px;
  width: 32px;
  height: 32px;
}

.whiteSection {
  color: var(--green);
  background-color: white;
  padding: 24px;
  margin: -24px;
  margin-top: 32px;
  margin-bottom: -10px;
  width: 100%;
}

.yellowSection {
  color: var(--green);
  background-color: var(--humamy-yellow);
  padding: 24px;
  margin: -24px;
  width: 100%;
}

.blueSection {
  color: white;
  background-color: var(--lagoon);
  padding: 24px;
  margin: -24px;
  margin-bottom: 0;
  padding-bottom: 32px;
  width: 100%;
}

.lightBlueSection {
  color: var(--green);
  background-color: var(--light-lagoon);
  padding: 24px;
  margin: -24px;
  margin-bottom: 0;
  padding-bottom: 28px;
  width: 100%;
}

.shippingSection {
  color: black;
  background-color: #fdfdf1;
}

.greySection {
  background: var(--light-grey) no-repeat url("/public/images/box.webp");
  background-position: top right;
  background-size: 35%;
  color: var(--green);
  padding: 24px;
  margin: -24px;
  margin-top: 0;
  margin-bottom: -10px;
  width: 100%;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 115%;
  letter-spacing: -0.72px;
  margin-bottom: 24px;
  width: 95%;
}

.smallTitle {
  font-size: 18px;
  font-weight: 800;
  line-height: 124%;
  letter-spacing: -0.3px;
  margin-bottom: 24px;
  width: 95%;
}

.stepTitle {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.2px;
  text-align: left;
  margin-bottom: -4px;
}

.sectionBody {
  font-size: 15px;
  font-weight: 400;
  line-height: 133.5%;
}

.premiumIcon {
  position: relative;
  bottom: 0.7ex;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  background: url("/public/icons/premium.svg") no-repeat;
  background-size: cover;
}

.noteWithIcon {
  font-size: 13px;
  color: var(--dark-grey);
  display: flex;
  gap: 8px;
  line-height: 1.3;
  align-items: center;
  opacity: 0.7;
}
.noteWithIcon img {
  opacity: 0.7;
}

.modalBtn {
  width: 100%;
  height: 60px;
}

.ctaBar {
  position: fixed;
  top: calc(100dvh - 125px);
  margin-left: -24px;
  width: 100vw;
  max-width: 500px;
  background: var(--light-grey);
  /* box-shadow: 0px 0px 54px #FDC300; */
  height: 125px;
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 16px;
  color: var(--green);
  text-align: center;
}

.white > div > div > .ctaBar {
  background: white;
}

.membershipModalSimplified .ctaBar,
.newMembershipDetails .ctaBar {
  background: linear-gradient(
    0deg,
    #fff 52.48%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  align-items: flex-end;
}

.scrollHint {
  left: 50%;
  position: fixed;
  bottom: 92px;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, -10px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
}

.headerSteps {
  display: flex;
  justify-content: center;
  gap: 8px;
  top: -45px;
  position: relative;
}

.backButton {
  float: left;
  margin-top: 12px;
}

.productsSearch > span {
  padding-right: 4px !important;
}

.filtersBar {
  display: flex;
  padding-left: 16px;
  padding-right: 5px;
  align-items: center;
  max-width: 100%;
  height: 54px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: 0.3s;
  background: white;
  top: 0;
  z-index: 20;
}

.filtersBar::-webkit-scrollbar {
  display: none;
}

.filtersBarWrapper {
  padding: 10px 0;
  position: sticky;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 20;
}

.searchContainer {
  overflow: hidden;
  flex: 1;
  height: 54px;
  padding-top: 2px;
}

.searchBtn {
  padding: 8px;
  padding-right: 16px;
  margin-top: 4px;
  cursor: pointer;
}

.filterPill {
  border-radius: 20px;
  border: 2px solid var(--light-grey);
  display: flex;
  padding: 0 15px;
  height: 80%;
  white-space: nowrap;
  margin-right: 8px;
  flex: 0;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
}
.filterPill.selected {
  border: 2px solid #c4ccd9;
}

.filterPill span {
  transition: 0.2s;
  margin-top: 2px;
}

/* FREEMIUM MODAL --------------------------------------------------------------------------- */

.freemiumModal > div:nth-child(2) {
  overflow-y: hidden;
}

.freemiumModal > div > div > div > button {
  color: var(--dark-gray);
}

.newFreemium .bannerModal {
  background: var(--Light-Blue, #cddfe3)
    url("/public/images/membership-dishes-freemium-new.webp") no-repeat;
  background-size: cover;
  background-position: center 0;
}

.productsRow {
  padding: 0 8px;
}

.bannerModal {
  height: calc(100dvh - 206px);
  width: 100%;
  background: var(--Light-Blue, #cddfe3);
  margin-left: -8px;
  margin-top: 24px;
  width: calc(100% - 32px) !important;
  color: var(--green);
  padding: 24px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.newFreemium .modalImage {
  position: absolute;
  z-index: -5;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: auto;
  align-content: stretch;
  gap: 2%;
}

.newFreemium .flexContainer {
  justify-content: center;
  align-items: center;
  padding-top: 70px;
}

.newFreemium .sectionTitle {
  text-align: center;
  font-size: 26px;
}

.strikedPrice {
  text-decoration-line: line-through;
  font-weight: 500 !important;
  opacity: 0.7;
}

.premiumIcon {
  position: relative;
  bottom: 0.7ex;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  background: url("/public/icons/premium.svg") no-repeat;
  background-size: cover;
}

.newFreemium .highlightYellow {
  font-size: 75px;
  padding: 0.3em 0.2em 0.5em 0.2em;
  margin-top: 16px;
}

.ul {
  padding: 20px;
}

.ul > li {
  margin-bottom: 8px;
  list-style: circle;
}

.bannerRow {
  transition: 0.3s;
  overflow: hidden;
  width: 100%;
}

.bannerRow.simple {
  margin-bottom: -8px;
}

.productsSpace {
  padding: 0;
}

@media only screen and (min-width: 992px) {
}

.headerButtonLabel {
  display: none !important;
  position: absolute;
}

@media only screen and (min-width: 537px) {
  .fullscreenModal {
    margin: 0 auto;
  }
  .headerButtonLabel {
    display: inline-block !important;
  }
}
