.modalImage {
    margin: 24px;
    margin-bottom: 8px;
}

.dishTitle {
    font-size: 15px;
    text-align: center;
    color: var(--dark-grey);
    margin-bottom: 24px;
}

.smallLabel {
    font-size: 12px;
    max-width: 100px;
    color: var(--disabled-grey);
    line-height: 1.3em;
}

.question {
    font-size: 20px;
    text-align: center;
    color: var(--dark-grey);
    margin-bottom: 24px;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.2em;
}

.rateCol {
    width: 70%;
    margin: 0 auto;
}

@media only screen and (max-width: 450px) {
    .rateCol {
        width: 100%;
    }
  }
  
