.trackingCard {
  width: 100%;
  border-radius: 0;
  border-width: 0;
  /* margin-top: 16px;
    margin-bottom: 16px; */
  display: flex;
  justify-content: space-between;
}

.trackingButton {
  width: 100%;
  background: var(--black) !important;
  color: white;
  /* margin-top: 16px; */
  height: 50px;
}
.trackingButton:global.ant-btn.ant-btn-default:hover,
.trackingButton:global.ant-btn.ant-btn-default:focus,
.trackingButton:global.ant-btn.ant-btn-default:active {
  color: white !important;
}

.buttonIcon {
  float: right;
  font-size: 20px;
  margin-top: 2px;
}

.moreInfoIcon {
  padding: 10px;
  margin: -10px;
  margin-top: -8px;
}

.moreInfoBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--grey);
  /* padding: 8px 0 8px 16px; */
  text-decoration: underline;
  margin-left: auto;
}
