.button {
  height: 50px;
  width: 100%;
  background: #fdc300;
  font-weight: 800;
  font-size: 18px;
  border-radius: 0;
  border: none;
  width: calc(100%);
  color: #1e1e1e;
  box-shadow: none !important;
}

.button.primary {
  background: #fdc300;
}

.button:disabled {
  background-color: #aeb5c0;
  color: white;
}

.button:active {
  opacity: 0.4;
}

.input {
  background-color: #f5f6fa !important;
  border-radius: 0 !important;
  height: 50px !important;
  width: 100% !important;
  box-shadow: none !important;
  margin-bottom: -100px !important;
  border: none !important;
}

.title {
  color: #00603a !important;
}

.fixedWidth {
  width: 360px;
}

.logoCentered strong {
  color: #00603a;
  font-size: 16px;
  float: left;
  width: 100%;
  text-align: center;
}

.error {
  color: #ff5d39;
  font-size: 16px;
  font-weight: 800;
}

.center {
  text-align: center;
  width: 100%;
  margin-top: 30%;
}

.textCenter {
  text-align: center;
  padding: 0 32px !important;
  margin: 0;
  max-width: 400px;
}

.textLeft {
  text-align: left;
  margin: 0 0 8px 0 !important;
}

.textMail {
  position: fixed;
  padding: 32px 0 !important;
  background: #f5f6fa;
  width: 100%;
  text-align: center;
  bottom: 0;
  margin-bottom: 0;
  left: 0;
}

.mail {
  color: #444845;
  text-decoration: underline;
  transition: 0.2s;
}

.mail:hover {
  opacity: 0.7;
  color: #444845;
  text-decoration: underline;
}

.imgMobile {
  width: 100%;
  display: none;
}

.loginBanner {
  background-color: #00603a;
  color: white;
  font-weight: 800;
  font-size: 15px;
  padding: 8px;
  width: 100vw;
  text-align: center;
}

.logo {
  width: 80px;
  margin: 0 auto;
  /* background-color: white; */
  padding: 24px;
  margin-bottom: -32px;
}

.logoTop {
  width: 75px;
  text-align: center;
  position: fixed;
  top: 2vh;
  left: 50%;
  margin-left: -40px;
}

.imgDesktop {
  display: none;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  /* width: calc(100% - 48px); */
}

.form {
  padding: 0 24px;
  padding-bottom: 16px;
}

.dark {
  color: #1e1e1e;
}

.textIntro {
  margin: 16px 8vh;
  color: #8f959e;
  text-align: center;
  font-size: 15px;
}

.spinner {
  justify-content: center;
  display: flex;
}

.socialLoginBtn {
  background: #f5f6fa !important;
  font-weight: 800;
  font-size: 18px !important;
  box-shadow: none !important;
  width: calc(100%) !important;
  color: #1e1e1e !important;
  font-family: sofia-pro, sans-serif !important;
  margin-bottom: 20px;
  border-radius: 0;
  border: none;
  height: 50px;
}

.collapse {
  border: 1px solid #f5f6fa;
  border-radius: 0;
  margin-top: 24px;
  margin-bottom: 8px;
}

:global .ant-collapse-header {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  padding-left: 24px !important;
}

@media screen and (min-width: 768px) {
  .textMail {
    position: relative;
    margin: 15vh auto;
    border-radius: 20px;
  }

  .imgMobile {
    display: none;
  }

  .imgDesktop {
    display: block;
    left: 0;
    width: 50%;
    height: 100%;
    position: fixed;
    background-size: cover !important;
    background: url("/public/images/login-desktop.png");
    background-position: center;
  }

  .rightArea {
    width: 50%;
    right: 0px;
    position: absolute;
    /* bottom: 20dvh; */
  }

  .bottom {
    position: relative;
    padding: 48px;
    width: auto;
    max-width: 500px;
    margin: 0 auto;
  }

  .textIntro {
    font-size: 16px;
  }
}

@media screen and (max-width: 376px) {
  .textIntro {
    margin: 16px;
  }
}

:global .ant-form-item-explain-error {
  position: relative;
}
