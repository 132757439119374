.productCard {
  margin: 16px;
  min-height: 134px;
  border-radius: 0;
  border-width: 0;
  background-color: #f5f6fa;
  overflow: hidden;
  transition: 0.2s;
}

.productCard.vertical {
  background-color: transparent;
  /* padding-bottom: 16px; */
  margin: 0;
  height: 100%;
}

.imageTag {
  position: absolute;
  width: 50%;
  height: auto;
}

.imageTag>.imageWrapper>img {
  width: 100%;
  height: auto;
  position: relative;
}

.imageTag.sm {
  width: 20%;
}

.imageTag.tl {
  top: 2%;
  left: 2%;
}

.imageTag.tr {
  top: 2%;
  right: 2%;
}

.imageTag.br {
  bottom: 2%;
  right: 2%;
}

.imageTag.bl {
  bottom: 2%;
  left: 2%;
}

.productCard:hover {
  background-color: #ebedf4;
}

.productCard.vertical:hover {
  background-color: var(--light-grey);
}

.productCard.vertical:hover .addCta {
  background-color: white;
}

.productCard.vertical:hover .expandIcon {
  background-color: white;
}

.productCard.inCart {
  outline: 2px solid #cfd4dc;
}

.productCard.vertical.inCart {
  outline: none;
  /* box-shadow: 0px 10px 30px 0px rgba(0, 53, 65, 0.07); */
  background-color: var(--light-grey);
}

.imageContainer {
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
}

.expandIcon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  border-radius: 8px;
  transition: 0.2s;
}

.vertical .image {
  background-position: center 100%;
  background-size: cover;
  /* height: 32vw; */
  background-repeat: no-repeat;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 12px;
  padding-right: 12px !important;
  min-height: 134px;
}

.membershipFirstRow .details {
  padding-right: 8px !important;
}

.title {
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  color: #00603a;
  padding-right: 6px;
  padding-bottom: 8px;
  line-height: 20px;
}

.vertical .title {
  text-align: center;
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  padding: 0 10px 16px 10px;
  color: var(--dark-grey);
}

.macrosRow {
  margin-bottom: 16px;
  gap: 10px;
}

.macroLabel {
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--disabled-grey);
  letter-spacing: 0.04em;
}

.macroValue {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: var(--black);
}

.cartEditButtonsContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.editBtn {
  width: 30px !important;
  height: 30px;
  background-color: #dbdfe4;
  border-radius: 21px;
  display: flex;
  flex-wrap: wrap;
  border: none;
  box-shadow: none;
  align-items: center;
  align-content: center;
  padding: 0;
  transition: 0.2s;
}

.editBtn:hover,
.editBtnSmall:hover {
  background-color: #b3b9c1;
  opacity: 1;
}

.editBtn>*:disabled:nth-child(1):hover,
.editBtnSmall>*:disabled:nth-child(1):hover {
  pointer-events: none;
}

:global .ant-tooltip-disabled-compatible-wrapper:hover {
  background-color: #dbdfe4;
}

.editBtn>*:disabled:nth-child(1) {
  width: 30px !important;
  height: 30px;
  background: none;
  border: none;
  /* background-color: #ffffff;
      border-radius: 30px; */
  opacity: 0.3;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 0;
  pointer-events: none;
}

.editBtnSmall {
  width: 24px !important;
  height: 24px;
  background-color: #dbdfe4;
  border-radius: 21px;
  display: flex;
  flex-wrap: wrap;
  border: none;
  box-shadow: none;
  align-items: center;
  align-content: center;
  padding: 0;
  transition: 0.2s;
}

.editBtnSmall>*:disabled:nth-child(1) {
  width: 24px !important;
  height: 24px;
  background: none;
  border: none;
  opacity: 0.3;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 0;
  pointer-events: none;
}

.editIcon {
  margin: 0 auto;
}

.qtyLabel {
  font-weight: 600;
  font-size: 15px;
  color: var(--dark-grey);
  text-align: center;
  letter-spacing: -0.03em;
  width: 36px;
}

.detailsBtn {
  font-weight: 500;
  font-size: 13px;
  text-decoration-line: underline;
  color: #00603a;
  display: flex;
  gap: 1px;
  align-items: center;
}

.detailsBtn img {
  padding-bottom: 1px;
  height: 15px;
}

.cartEditButtonTooltip {
  width: 100%;
}

.verticalCardContainer {
  padding: 8px;
}

.addCta {
  height: 36px;
  background: var(--light-grey);
  font-weight: 600;
  font-size: 13px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  flex-grow: 1;
  box-shadow: none;
  margin-right: 16px;
  margin-left: 16px;
}

.addCta:global.ant-btn.ant-btn-default:hover {
  background: var(--green);
  color: white !important;
}

.addCta button {
  width: 100%;
  height: 100%;
}

.addCta :global button.ant-btn.ant-btn-default:disabled {
  background-color: var(--light-grey);
  color: var(--disabled-grey) !important;
  font-weight: 600;
  font-size: 13px;
}

.addCta :global button.ant-btn.ant-btn-default:disabled:hover {
  background-color: var(--light-grey);
  font-weight: 600;
  font-size: 13px;
}

/*.verticalCardContainer:nth-child(even) .productCard {*/
/* margin-right: 8px;*/
/*}*/
/*.verticalCardContainer:nth-child(odd) .productCard {*/
/*  margin-left: 8px;*/
/* }*/

/* MODAL --------------------------------------------------------------------------- */

.fullscreenModal {
  margin: 0;
}

.fullscreenModal>div:nth-child(2) {
  border-radius: 0;
  height: -webkit-fill-available;
  height: 100dvh;
  width: 100vw;
  max-width: 500px;
  position: fixed;
  overflow: visible;
  overflow-y: scroll;
}

.fullscreenModal :global .ant-modal-footer {
  height: 70px;
}

.padding0>div:nth-child(2) {
  padding-bottom: 24px;
}

.closeIcon {
  margin-top: -20px;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.6));
  position: fixed;
}

.modalImage {
  margin: -24px;
  width: calc(100% + 48px) !important;
  /* background-color: #c9c9c9; */
}

.dots li {
  margin-bottom: 16px;
  width: 18px !important;
}

.dots li button {
  height: 10px !important;
  width: 10px !important;
  border-radius: 10px !important;
}

.detailsTitleRow {
  display: flex;
  margin-top: 40px;
  margin-bottom: 8px;
  justify-content: space-between;
}

.detailsTitle {
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #00603a;
  /* margin-top: 32px; */
  margin-bottom: 24px;
  margin-top: 0;
  max-width: calc(100% - 110px);
  padding-right: 16px;
}

.weightBadge {
  display: flex;
  padding: 7px 10px;
  background: #f5f6fa;
  font-weight: 500;
  font-size: 15px;
  color: #444845;
  height: 24px;
  align-items: center;
}

.nutritionalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.nutritionalDetail {
  display: flex;
  flex-flow: column;
}

.nutritionalValue {
  font-weight: 800;
  font-size: 16px;
  color: #474864;
}

.nutritionalLabel {
  font-weight: 500;
  font-size: 14px;
  color: #97a1b0;
}

.descriptionTitle {
  padding: 0;
  font-weight: 800;
  font-size: 16px;
  margin-bottom: -8px;
  color: #444845;
}

.descriptionBody {
  padding: 0;
  font-size: 15px;
  color: #444845;
  text-align: left;
  margin-top: -8px;
}

.descriptionBody :global(.allergen) {
  font-weight: bold;
}

.button {
  height: 60px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
}

.button:hover {
  background: #e8e9ee;
}

.button.yellow {
  background: #fdc300;
  color: black;
}

.button.yellow:hover {
  background: #edb600;
}

.button.primaryGreen {
  background: #00603a;
  color: white;
}

.button.primaryGreen:hover {
  background: #00492c;
  color: white;
}

.button:disabled {
  background-color: #aeb5c0;
  color: white;
  pointer-events: none;
}

.modalBtn {
  /* position: fixed;
      margin: -24px;
      top: calc(100dvh - 66px);
      left: 40px;
      width: calc(100vw - 36px);
      max-width: 500px;
      height: 70px; */

  position: fixed;
  top: calc(100dvh - 70px);
  left: 0;
  width: 100vw;
  max-width: 500px;
  height: 70px;
}

.modalEditBar {
  /* position: fixed;
      top: calc(100dvh - 90px);
      margin-left: -24px;
      width: calc(100vw - 36px);
      max-width: 500px;
      background-color: white;
      box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.1);
      height: 70px;
      display: flex;
      justify-content: center;
      gap: 25px; */

  position: fixed;
  top: calc(100dvh - 70px);
  margin-left: -24px;
  width: 100vw;
  max-width: 500px;
  background-color: white;
  box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.1);
  height: 70px;
  display: flex;
  justify-content: center;
  gap: 25px;
}

.editBtn.modalEditBtn,
.editBtn.modalEditBtn button:disabled {
  width: 40px !important;
  height: 40px;
}

.alert>div:nth-child(2) {
  border-radius: 0;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding-top: 32px;
}

:global .ant-modal .ant-modal-close {
  width: 40px;
  height: 40px;
}

.alertTitle {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: -0.4px;
  color: #444845;
  text-align: center;
  margin-bottom: 16px;
}

.alert .descriptionBody {
  text-align: center;
  margin-bottom: 24px;
  color: #8f959e;
}

/* MEDIA QUERIES ---------------------------------------------------------------- */

@media only screen and (min-width: 768px) {
  /* .fullscreenModal > div:nth-child(2) {
          height: calc(100vh - 36px);
          width: calc(100vw - 36px);
      } */
}

@media only screen and (min-width: 537px) {
  .modalBtn {
    left: 50%;
    margin-left: -260px;
  }

  .fullscreenModal {
    margin: 0 auto;
  }
}

/* Membership ------------------------------------------------------- */
:root {
  --membership-background-color: #008dab;
  --membership-card-border: #00677d;
  --membership-color: white;
}

.membershipCard,
.membershipCard.productCard:hover {
  background-color: var(--membership-background-color);
  color: var(--membership-color);
}

.membershipCard.inCart {
  outline: 2px solid var(--membership-card-border);
}

.membershipCard .title {
  color: var(--membership-color);
}

.membershipCard.simplified.notInCart .title {
  color: var(--black);
}

.membershipCard.simplified,
.membershipCard.simplified .title {
  font-size: 14px;
}

.membershipFirstRow {
  margin-bottom: 8px;
}

.membershipCard.simplified {
  margin: 16px 8px 16px 8px;
}

.membershipCard.simplified.notInCart {
  outline: none;
  background-color: var(--light-grey);
}

.membershipCard .price {
  color: white;
}

.membershipCard.simplified .price {
  font-weight: 500;
}

.membershipCard.simplified.notInCart .price {
  color: var(--black);
}

.membershipCard .detailsBtn {
  color: var(--membership-color);
}

.membershipCard.simplified.notInCart .detailsBtn {
  color: var(--black);
}

/* PriceBox ------------------------------------------------------- */

.premiumIcon {
  position: relative;
  bottom: 0.7ex;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  background: url("/public/icons/premium.svg") no-repeat;
  background-size: cover;
}

.price {
  text-decoration-line: none;
  font-size: 14px;
  font-weight: bold;
  /* color: inherit; */
  margin-right: 4px;
}

.productCard.vertical .price {
  margin-bottom: 8px;
}

.strikedPrice {
  text-decoration-line: line-through;
  font-weight: 500 !important;
  opacity: 0.5;
}

.highlightedPrice {
  font-weight: bold;
}

/* MEMBER BANNER -------------------------------------------------- */

.imageBadge {
  width: 29px;
  height: 28px;
  float: left;
  margin-left: 8px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.microCopy {
  font-size: 14px;
  color: #606060;
}

/* MEDIA QUERIES ----------------------------------------------------- */

@media screen and (min-width: 768px) {
  /* .vertical .image {
    height: 15vw;
  } */
}

@media screen and (min-width: 992px) {
  /* .vertical .image {
    height: 12vw;
  } */
}

@media screen and (min-width: 1200px) {
  /* .vertical .image {
    height: 13vw;
  } */

  .macrosRow {
    gap: 24px;
  }

  .macroLabel {
    font-size: 12px;
  }

  .macroValue {
    font-size: 14px;
  }

}