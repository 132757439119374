:root {
  --humamy-yellow: #fdc300;
  --humamy-yellow-alt: #edb600;
  --green: #00603a;
  --lagoon: #008dab;
  --light-grey: #f5f5f5;
  --microcopy-text: #8f959e;
  --dark-grey: #444845;
  --black: #1e1e1e;
  --disabled-grey: #8f959e;
  --light-green: #d4dfdb;
  --lighter-green: #e9eeec;
  --light-lagoon: #cddfe3;
  --light-red: #ffeae5;
  --red: #ff5d39;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* INPUT ---------------------------------------------------------- */

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  button {
  background-color: transparent;
  display: block;
  position: absolute;
  top: 13px;
  right: 52px;
  z-index: 1;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  button
  div {
  display: none;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  button
  span
  span[aria-label="search"] {
  display: none;
}

.ant-input-group-addon {
  background-color: transparent !important;
}

.ant-input-affix-wrapper .ant-input-suffix {
  display: block;
  position: absolute;
  top: 16px;
  right: 32px;
  z-index: 1;
  background-color: transparent;
}

.styles_searchContainer__nZLOV .ant-input-affix-wrapper .ant-input-suffix {
  top: 11px;
}

.ant-form-item-extra {
  line-height: 1.4 !important;
  padding-top: 8px;
}

label {
  /* font-size: 15px !important; */
  font-weight: 700;
  line-height: 1.3;
}

.ant-progress-inner {
  background-color: #e9efed !important;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 10;
}

/* BUTTONS -------------------------------------------------------------------- */

.ant-btn {
  font-weight: 800;
  border: none;
}

.ant-btn.ant-btn-default:disabled {
  color: var(--light-grey) !important;
}

.ant-btn.ant-btn-default:disabled:hover {
  color: var(--light-grey) !important;
}

.ant-btn.ant-btn-primary {
  background: var(--humamy-yellow);
}

.ant-btn.ant-btn-primary:hover,
.ant-btn.ant-btn-primary:focus,
.ant-btn.ant-btn-primary:active {
  background: var(--humamy-yellow-alt) !important;
}

.ant-btn.ant-btn-default:hover,
.ant-btn.ant-btn-default:focus,
.ant-btn.ant-btn-default:active {
  color: black !important;
}

/* DROPDOWN ------------------------------------------------------------------------ */

.ant-dropdown {
  font-size: 15px;
}

.ant-dropdown-trigger {
  color: var(--black) !important;
}

.ant-dropdown-trigger.ant-space {
  padding: 16px;
  background-color: var(--light-grey);
  width: 100%;
  justify-content: space-between;
}

.ant-dropdown-menu {
  border-radius: 0 !important;
  margin-top: -4px !important;
}

.ant-dropdown-menu-item {
  padding: 8px 12px !important;
}

.ant-layout-header {
  background-color: white;
  padding: 0;
}

.anticon-menu {
  z-index: 10;
}

/* MODALS ------------------------------------------------------------------------------ */

.ant-modal-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

/* MENU ------------------------------------------------------------------------------------ */

.ant-menu-overflow-item,
.ant-menu-submenu,
.ant-menu-item-active::after,
.ant-menu-item::after,
.ant-menu-item-selected::after {
  border-bottom-width: 0 !important;
}
.ant-menu-item:hover {
  font-weight: 500;
}

.ant-menu-item-active,
.ant-menu-item-selected {
  font-weight: 600;
}

.ant-menu-horizontal {
  border-bottom: 0px solid rgba(5, 5, 5, 0.06);
}

:where(.css-dev-only-do-not-override-rc9x9x).ant-menu-horizontal {
  border-bottom: 0px solid rgba(5, 5, 5, 0.06) !important;
}
.ant-menu-overflow {
  width: 100%;
}
