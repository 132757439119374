.menuWrapper {
  position: fixed !important;
  right: 0;
  z-index: 30;
  height: 100%;
  box-shadow: -100px 0px 800px rgb(0, 0, 0, 0.1);
  -webkit-box-shadow: -100px 0px 800px rgb(0, 0, 0, 0.1);
  -webkit-appearance: none;
  /* transition: 0.1s !important; */
}

.menuWrapper > span {
  top: 24px !important;
}

.menu {
  margin-top: 24px;
}

.menuDesktop {
  display: flex;
  align-items: center;
  height: 90px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}

.overlayExpanded {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.overlayCollapsed {
  display: none;
}

:global .ant-menu-item {
  text-align: left;
  background-color: white !important;
  color: #696969;
}

:global .ant-menu-item-active {
  font-weight: 600;
}

:global .ant-menu-item-selected {
  font-weight: 600;
}

.supportBtn {
  background-color: white;
  border-radius: 40px;
  transition: 0.2s;
  position: fixed;
  bottom: 224px;
  left: 24px;
  box-shadow: 0px 0px 54px 0px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  padding: 8px 15px 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  font-weight: bold;
  color: #00603a;
}

.supportBtn:hover {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  /* background-color: #DBDFE4; */
}

.smallBanner {
  background: var(--green);
  color: white;
  text-align: center;
  padding: 8px 16px 12px 16px;
  font-weight: 600;
  font-size: 15px;
  margin: -24px;
  margin-top: 0;
  margin-bottom: 0;
}

/* MEDIA QUERIES ---------------------------------------------------------------- */

@media only screen and (min-width: 992px) {
  .menuWrapper {
    /* box-shadow: none; */
    display: none;
  }

  .menuItemIcon {
    display: none !important;
  }

  .overlayExpanded {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  /* 
  .menuDesktop {
    display: none;
  } */
}
