.titleCentered {
  font-size: 18px;
  font-weight: 800;
  color: var(--black);
  text-align: center;
}

.titleSmall {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.text {
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  color: var(--dark-gray);
  margin-bottom: 24px;
  margin-top: 16px;
}

.textLeft {
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  color: var(--dark-gray);
  margin-bottom: 0;
}

.frequencyDropdown {
  width: 100%;
}

.boxSelector {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--light-green);
  margin-bottom: 8px;
}
.boxSelector.boxSelectorActive {
  border: 2px solid var(--Green, #00603a);
}

.largeText {
  font-size: 24px;
}

.fullWidthBanner {
  background: var(--light-lagoon);
  margin: -24px;
  margin-top: 32px;
  color: var(--green);
  padding: 24px;
}

.modalImage {
  margin: -24px;
  width: calc(100% + 48px) !important;
}

.title {
  font-size: 24px;
  margin-top: -4px;
  color: var(--green);
  line-height: 30px;
}

.smallTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.listItem {
  display: flex;
  flex-flow: row;
  padding-right: 5px;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  color: var(--green);
  margin-bottom: 16px;
  align-items: flex-start;
  line-height: 128.5%;
}

.carousel {
  display: flex;
  width: calc(100% + 48px);
  gap: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-top: 24px;
  margin-bottom: -24px;
}
.carousel::-webkit-scrollbar {
  display: none;
}

.imageContainer {
  width: 250px;
  position: relative;
}
.image {
  width: 100%;
}
.expandIcon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.carouselTitle {
  color: var(--green);
  font-weight: 600;
  line-height: 1.2;

  /* Bigger images */

  font-size: 14px;
  margin-top: 16px;

  /* Smaller images */
  /* font-size: 14px;
  margin-top: 8px;
  line-height: 19px; */
}

.modalEditBar {
  position: fixed;
  top: calc(100dvh - 92px);
  margin-left: -24px;
  width: 100vw;
  max-width: 500px;
  background-color: white;
  box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.1);
  height: 92px;
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 16px;
}

.button {
  height: 60px;
  width: 100%;
  background: #f5f6fa;
  font-weight: 800;
  font-size: 16px;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.button.primary {
  background: #fdc300;
}
.button:disabled {
  background-color: #aeb5c0;
  color: white;
}
.button:hover {
  background: #dfe0e6;
}
.buttonLeftAligned {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.banner {
  width: 100%;
  margin-bottom: 8px;
  margin-top: 8px;
  background-color: var(--light-grey);
  overflow: hidden;
  padding: 24px;
}

.editIcon {
  padding: 10px;
  margin: -10px;
  margin-top: -8px;
}
.editIcon:active {
  opacity: 0.5;
}

.subEditBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--disabled-grey);
  padding: 8px 0 8px 16px;
  text-align: right;
}

/* MEDIA QUERIES -------------------------------------------------------------------- */

@media (min-width: 992px) {
  /* .carousel {
    scrollbar-width: thin;
  }
  .carousel::-webkit-scrollbar {
    display: unset;
  } */
}
